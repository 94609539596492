import store from '../../redux/store/configureStore';
import {
	SIP_UPDATE_RECORDING_STATE
} from '../../redux/constants/constants';
import {
	handleSavingRecordedSip
	, continueSipTransfer
} from '../../redux/actions/async/errand';
import {
	sipCallIsRecording
} from '../../redux/selectors/call';
import {
	BEEP_AUDIO_SRC
} from './constants';

let timeStartRecord, timeEndRecord;
let remoteStream, localStream;
let combiMediaRecorder;
let recordedCombiBlob;
let streamBeeper;
let srcBeepElem;

function isEmpty(obj) {
    for (var x in obj) { return false; }
    return true;
}

export const startRecordingSip = (eid, streams, isManual) => (dispatch, getState) => {
	console.info("startRecordingSip called");
	if(!isEmpty(streams)) {
		localStream = streams.localStream;
		remoteStream = streams.remoteStream;
	}
	let audioContext = new AudioContext();
	let started = false;
	timeStartRecord = window.performance.now();
	recordedCombiBlob = [];
	let options = {mimeType: 'audio/webm'};
	if (!MediaRecorder.isTypeSupported(options.mimeType)) {
		console.error(`${options.mimeType} is not supported`);
		options = {mimeType: ''};
	}

    try {
		if(remoteStream && localStream) {
			let audio_01 = audioContext.createMediaStreamSource(remoteStream);
			let audio_02 = audioContext.createMediaStreamSource(localStream);
			streamBeeper = new Audio(BEEP_AUDIO_SRC);
			srcBeepElem =
				audioContext.createMediaElementSource(streamBeeper);
			let dest = audioContext.createMediaStreamDestination();
			audio_01.connect(dest);
			audio_02.connect(dest);
			srcBeepElem.connect(dest);
			streamBeeper.play();
			combiMediaRecorder = new MediaRecorder(dest.stream, options);
		} else {
			console.log("No streams found");
			return false;
 		}
	} catch (e) {
		console.error('Exception while creating MediaRecorder:', e);
		return true;
	}

	if(combiMediaRecorder) {
		combiMediaRecorder.onstop = (event) => {
			//console.info("combiMediaRecorder.onstop triggered");
 		};

		combiMediaRecorder.addEventListener('dataavailable',
				e => handleDataAvailable(eid, "combined",  e, isManual,
				audioContext));
		combiMediaRecorder.start();
		started = true;
	}
	if(started){
		store.dispatch(updateSipRecording(true));
	}
	console.info("combiMediaRecorder state:",combiMediaRecorder.state);
	return true;
}

export const updateSipRecording = (isRecording) => {
	return {
		type: SIP_UPDATE_RECORDING_STATE
		, payload: {
			isRecording: isRecording
		}
	}
}

function handleDataAvailable(errandId, type, event, isManual, audioContext) {
	let timeRecorded = (timeEndRecord - timeStartRecord) / 1000;
	recordedCombiBlob.push(event.data);
	store.dispatch(handleSavingRecordedSip(errandId, type ,
		recordedCombiBlob, timeRecorded, isManual))
	.then(results => {
		store.dispatch(continueSipTransfer(false));
	});
	combiMediaRecorder = null;
	srcBeepElem.disconnect()
	if(typeof audioContext !== 'undefined' && audioContext != null){
		audioContext.close();
	}
}

export const stopRecordingSip = (eid) => (dispatch, getState) => {
	console.info("stopRecordingSip called");
	timeEndRecord = window.performance.now();
	let isRecording = sipCallIsRecording(getState())
	let isPaused = isRecordingPaused();
	if(isRecording || isPaused) {
		if(combiMediaRecorder && combiMediaRecorder.state !== "inactive"){
			combiMediaRecorder.stop();
		}
		let timeRecorded = (timeEndRecord - timeStartRecord) / 1000;
		dispatch(updateSipRecording(false));
	}
}

export const stopAutoRecordingSip = (eid) => (dispatch, getState) => {
	console.info("stopAutoRecordingSip called");
	timeEndRecord = window.performance.now();
	if(combiMediaRecorder != null) {
		if(combiMediaRecorder && combiMediaRecorder.state !== "inactive"){
			combiMediaRecorder.stop();
		}
		let timeRecorded = (timeEndRecord - timeStartRecord) / 1000;
		dispatch(updateSipRecording(false));
	}
}

export const pauseRecordingSip = () => (dispatch, getState) => {
	if(combiMediaRecorder != null) {
		console.info("pauseRecordingSip combiMediaRecorder.state:",
				combiMediaRecorder.state);
		if(combiMediaRecorder.state != "paused"){
			if(streamBeeper == null) {
				streamBeeper.play();
			}
			console.info("pausing");
			combiMediaRecorder.pause();
			dispatch(updateSipRecording(false));
		} else {
			console.info("resuming");
			combiMediaRecorder.resume();
			dispatch(updateSipRecording(true));
		}
	}
}

export const forceRecordingPause = () => (dispatch, getState) => {
	if(combiMediaRecorder != null) {
		if(combiMediaRecorder.state != "paused"){
			console.info("pausing");
			combiMediaRecorder.pause();
			dispatch(updateSipRecording(false));
		}
	}
}

export const forceRecordingResume = () => (dispatch, getState) => {
	if(combiMediaRecorder != null) {
		if(combiMediaRecorder.state == "paused"){
			console.info("resuming");
			combiMediaRecorder.resume();
			dispatch(updateSipRecording(true));
		}
	}
}

export const isRecordingPaused = () => {
	if(combiMediaRecorder != null) {
		if(combiMediaRecorder.state == "paused"){
			return true;
		}
	}
	return false;
}
